export class Utente { 
    id?: number; 
    cognome?: string; 
    nome?: string; 
    data_nascita?: Date; 
    email?: string;
    username?: string;
    password?: string;
    nickname?: string;
    immagine?: string;
    biografia?: string;
    ruolo?: string;
    genitore1?: string;
    genitore2?: string;
}