import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/galleria',
    name: 'galleria',
    component: () => import('../views/GalleriaView.vue')
  },
  { path: '/portfolio',
    name: 'portfolio',
    component: () => import('../views/PortfolioView.vue')
  },
  { path: '/nuova-opera',
    name: 'nuova-opera',
    component: () => import('../views/OperaNew.vue')
  },
  { path: '/modifica-opera/:id',
    name: 'modifica-opera',
    component: () => import('../views/OperaEdit.vue')
  },
  { path: '/gestione-utenti',
    name: 'gestione-utenti',
    component: () => import('../views/GestioneUtentiView.vue')
  },
  { path: '/nuovo-utente',
    name: 'nuovo-utente',
    component: () => import('../views/UtenteNew.vue')
  },
  { path: '/profilo-utente',
    name: 'profilo-utente',
    component: () => import('../views/ProfiloUtenteView.vue')
  }
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes
})

export default router;
